import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NewHome from "@/views/NewHome.vue";
import ProductCenter from "@/views/ProductCenter.vue";
import Counseling from "@/views/Counseling.vue";
import Solution from "@/views/Solution.vue";
import BossiPaasCenter from "@/views/BossiPaasCenter.vue";
import BossReportCenter from "@/views/BossReportCenter.vue";
import BossMaCenter from "@/views/BossMaCenter.vue";
import BossAI from "@/views/BossAI.vue";
import OneCompanyDoor from "@/views/OneCompanyDoor.vue";
import BossBICenter from "@/views/BossBICenter.vue";
import HomeSolution from "@/views/HomeSolution.vue";
import HouseholdApplianceSolution from "@/views/HouseholdApplianceSolution.vue";
import ElectronSolution from "@/views/ElectronSolution.vue";
import MachineryManagementSolution from "@/views/MachineryManagementSolution.vue";
import FoodstuffSolution from "@/views/FoodstuffSolution.vue";
import MetalSolution from "@/views/MetalSolution.vue";
import HardwareSolution from "@/views/HardwareSolution.vue";
import CeramicsSolution from "@/views/CeramicsSolution.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',//首页
    name: 'NewHome',
    component: NewHome
  },
  {
    path: '/about/about',
    name: 'about',
    component:resolve => require(["../views/About.vue"], resolve)
  },
  {
    path: '/case',
    name: 'case',
    // props: true,
    component:resolve => require(["../views/case.vue"], resolve)
  },
  {
    path: '/contact',
    name: 'contact',
    component: resolve => require(['../views/Contact.vue'], resolve)
  },
  {
    path: '/quarterly',
    name: 'quarterly',
    component: resolve => require(['../views/quarterly.vue'], resolve)
  },
  {
    path: '/quarterlyFile/:path/:id',
    name: 'quarterlyFile',
    props: true,
    component: resolve => require(['../views/quarterlyFile.vue'], resolve)
  },
  {
    path: '/programme',//方案
    name: 'programme',
    props: true,
    component: resolve => require(['../views/programme.vue'], resolve)
  },
  {
    path: '/ziji',//资质
    name: 'ziji',
    component: resolve => require(['../views/ziji.vue'], resolve)
  },
  {
    path: '/service',//服务
    name: 'service',
    component: resolve => require(['../views/service.vue'], resolve)
  },
  {
    path: '/product', //经典案例
    name: 'product',
    component: resolve => require(['../views/product.vue'], resolve)
  },
  {
    path: '/serve/frontserve', //售前
    name: 'frontserve',
    component: resolve => require(['../views/frontserve.vue'], resolve)
  },
  {
    path: '/serve/afterserve', //售后
    name: 'afterserve',
    component: resolve => require(['../views/afterserve.vue'], resolve)
  },
  {
    path: '/serve/serve', //服务
    name: 'serve',
    component: resolve => require(['../views/serve.vue'], resolve),
  },
  {
     path: "/serve/bossdoc", //文档中心
     name: "BossDoc",
     component: resolve => require(['../views/BossDoc'], resolve),
  },
  {
    path: '/serve/complain', //投诉
    name: 'complain',
    component: resolve => require(['../views/complain.vue'], resolve)
  },
  {
    path: '/about/news', //新闻
    name: 'news',
    component: resolve => require(['../views/news.vue'], resolve)
  },
  // {
  //   path: '/productCenter', //产品中心
  //   name: 'ProductCenter',
  //   component: resolve => require(['../views/ProductCenter.vue'], resolve)
  // },
  {
    path: '/solution', //产品中心
    name: 'Solution',
    component: resolve => require(['../views/Solution.vue'], resolve)
  },
  {
    path:'/apply',//试用中心
    name:'apply',
    component: resolve => require(['../views/apply.vue'], resolve)
  },
  {
    path:'/productCenter/BossiPaasCenter',//BossiPaasCenter
    name:'BossiPaasCenter',
    component: resolve => require(['../views/BossiPaasCenter.vue'], resolve)
  },
  {
    path:'/productCenter/BossReportCenter',//bossDI设计中心
    name:'BossReportCenter',
    component: resolve => require(['../views/BossReportCenter.vue'], resolve)
  },
  {
    path:'/productCenter/BossAI',//应用系统中心
    name:'BossAI',
    component: resolve => require(['../views/BossAI.vue'], resolve)
  },
  {
    path:'/productCenter/oneCompanyDoor',//企业门户
    name:'OneCompanyDoor',
    component: resolve => require(['../views/OneCompanyDoor.vue'], resolve)
  },
  {
    path:'/productCenter/bossMaCenter',//bossMa低代码
    name:'BossMaCenter',
    component: resolve => require(['../views/BossMaCenter.vue'], resolve)
  },
  {
    path:'/productCenter/BossBICenter',//loT智能物联网
    name:'BossBICenter',
    component: resolve => require(['../views/BossBICenter.vue'], resolve)
  },
  {
    path:'/solution/homeSolution',//家居
    name:'HomeSolution',
    component: resolve => require(['../views/HomeSolution.vue'], resolve)
  },
  {
    path:'/solution/householdApplianceSolution',//家电
    name:'HouseholdApplianceSolution',
    component: resolve => require(['../views/HouseholdApplianceSolution.vue'], resolve)
  },
  {
    path:'/solution/electronSolution',//电子
    name:'ElectronSolution',
    component: resolve => require(['../views/ElectronSolution.vue'], resolve)
  },
  {
    path:'/solution/machineryManagementSolution',//机械管理
    name:'MachineryManagementSolution',
    component: resolve => require(['../views/MachineryManagementSolution.vue'], resolve)
  },
  {
    path:'/solution/foodstuffSolution',//食品
    name:'FoodstuffSolution',
    component: resolve => require(['../views/FoodstuffSolution.vue'], resolve)
  },
  {
    path:'/solution/metalSolution',//金属
    name:'MetalSolution',
    component: resolve => require(['../views/MetalSolution.vue'], resolve)
  },
  {
    path:'/solution/hardwareSolution',//五金
    name:'HardwareSolution',
    component: resolve => require(['../views/HardwareSolution.vue'], resolve)
  },
  {
    path:'/solution/ceramicsSolution',//陶瓷
    name:'CeramicsSolution',
    component: resolve => require(['../views/CeramicsSolution.vue'], resolve)
  },
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})
router.beforeEach((to, from, next) => {
  // document.documentElement.scrollTop = 0
  next();
  // var timer = null;
  // timer = setInterval(function () {
  //   var topH = document.documentElement.scrollTop || document.body.scrollTop;
  //   var stepLength = Math.ceil(topH / 5);
  //   document.documentElement.scrollTop = document.body.scrollTop = topH - stepLength;
  //   if (topH == 0) {
  //     clearInterval(timer);
  //   }
  // }, 30);
  
});
export default router
