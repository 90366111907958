<template>
  <div id="app" style="overflow: hidden" v-cloak>
    <transition name="fade">
      <div class="inav bg row row-no-gutters">
        <div class="logo col-xl-2">
          <b-img
            :src="logoUrl"
            alt="广东壹公里数智科技有限公司"
            style="max-width: 100%; max-height: 100%"
          ></b-img>
        </div>

        <b-container fluid="xl" class="nav col-xl-7">
          <b-navbar toggleable="xl" type="dark" variant="">
            <!-- <b-navbar-brand href="#/about" class="logo">

            </b-navbar-brand> -->

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="two-column">
                <li class="nav-item" v-for="item in navlist" :key="item.title">
                  <router-link
                    v-if="!item.list"
                    class="nav-link"
                    :to="item.path"
                    >{{ item.title }}
                  </router-link>
                  <b-nav-item-dropdown right v-else>
                    <template v-slot:button-content>
                      <span
                        :class="{ 'new-link': routerPath.includes(item.path) }"
                        style="display: inline"
                        >{{ item.title }}</span>
                    </template>
                    <b-dropdown-item v-if="item.listTitle">
                      <el-row :gutter="40">
                        <el-col :md="it.align=='left'?16:8"  v-for="it in item.list" :key="it.title">
                          <h4 @click="navBtnGroupHead(it.path)">{{ it.title }}</h4>
                          <my-router-link class="nav-link" :to="obj.path" v-for="obj in it.list" :key="obj.title" target="_blank">
                            <p class="title">{{obj.title}}</p>
                            <p class="text" v-if="it.text">{{ obj.text }}</p>
                          </my-router-link>
                        </el-col>
                      </el-row>
                    </b-dropdown-item>
                    <b-dropdown-item v-else>
                      <el-row :gutter="40">
                        <el-col :md="12" v-for="it in item.list" :key="it.title">
                          <router-link class="nav-link" :to="item.path + it.path">
                            <p class="title">{{it.title}}</p>
                            <p class="text" v-if="it.text">{{ it.text }}</p>
                          </router-link>
                        </el-col>
                      </el-row>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </li>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-container>
        <div class="header-right col-xl-3" style="width: 327px">
          <img
            src="./assets/images/home_slices/call.png"
            style="
              width: 18px;
              height: 18px;
              top: 19px;
              right: 0px;
              position: relative;
            "
          />
          <div
            style="
              font-size: 19px;
              font-family: SF Pro Display-Semibold, SF Pro Display;
              font-weight: 600;
              color: #1b212e;
              width: 152px;
              margin: 0px;
            "
          >
            0757-83785031
          </div>
          <div
            class="useCenter"
            @click="contactway3()"
            style="
              cursor: pointer;
              font-size: 23px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              float: right;
            "
          >
            试用中心
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-left" mode="out-in">
      <router-view />
    </transition>
    <footer
      v-show="!footerRouterHideArr.includes(routerName)"
      style="padding-top: 0"
    >
      <section class="contact">
        <b-container fluid="xl">
          <b-row class="mb-1">
            <b-col md="12" class="content container">
              <h1 style="">壹公里在您身边7*24小时 贴心服务</h1>
              <h2>联系我们</h2>
              <button>0757-83785031</button>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="contact2">
        <b-container fluid="xl">
          <b-row class="mb-1">
            <b-col md="12" class="content container">
              <h1 style="">企业联系方式</h1>
              <h2>为您提供专属的数字化解决方案</h2>
              <b-row class="mb-1">
                <b-col md="4">
                  <label>称呼</label>
                  <input type="text" v-model="bossContact.name" />
                </b-col>
                <b-col md="4">
                  <label>企业名称</label>
                  <input type="text" v-model="bossContact.ename" />
                </b-col>
                <b-col md="4">
                  <label>联系电话</label>
                  <input type="text" v-model="bossContact.telephone" />
                </b-col>
                <b-col md="4">
                  <label>企业邮箱</label>
                  <input type="text" v-model="bossContact.email" />
                </b-col>
                <b-col md="8">
                  <label>需求</label>
                  <input type="text" v-model="bossContact.reply" />
                </b-col>
                <b-col md="12">
                  <button @click="addContact()">提交</button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="footer">
        <b-container fluid="xl">
          <b-row class="footer1">
            <b-col
              md="4"
              lg="2"
              sm="4"
              v-for="item in footerNavList"
              :key="item.title"
            >
              <dl>
                <dt>{{ item.title }}</dt>
                <dd v-for="it in item.list" :key="it.title">
                  <router-link
                    class="nav-link"
                    :to="item.path + it.path"
                    style="color: #e5efff; padding: 0"
                    >{{ it.title }}</router-link
                  >
                </dd>
              </dl>
            </b-col>
            <b-col md="8" lg="4" sm="8">
              <dl class="code">
                <img
                  class="imgcall"
                  src="./assets/images/home_slices/call(1).png"
                />
                <dt>0757-83785031</dt>
                <!-- <dd class="codeImg">
                  <img src="./assets/images/weixin.jpg" alt="" />
                  <div class="text">扫码关注公众号了解壹公里动态</div>
                </dd> -->
                <dd class="codeImg">
                  <img
                    src="./assets/images/bossfront/gonzhonghao.jpg"
                    alt=""
                  />
                  <div class="text">扫码关注公众号了解壹公里动态</div>
                </dd>
                <dd class="codeImg">
                  <img style="background:#fff"
                    src="./assets/images/bossfront/douyinghao.png"
                    alt=""
                  />
                  <div class="text">扫码关注抖音号了解壹公里动态</div>
                </dd>
                <dd class="codeImg">
                  <img style="background:#fff"  
                    src="./assets/images/bossfront/shipinghao.png"
                    alt=""
                  />
                  <div class="text">扫码关注视频号了解壹公里动态</div>
                </dd>
              </dl>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid="xl">
          <b-row class="footer2">
            <b-col md="12">
              <img src="./assets/images/bossfront/Logo_white.png" alt="" />
            </b-col>
            <b-col md="12">
              <h3>广东壹公里数智科技有限公司</h3>
            </b-col>
            <b-col md="6" lg="3" sm="6">
              <dl>
                <dd>联系邮箱:&nbsp;&nbsp;&nbsp;chendanqing@bossrepost.net</dd>
                <dd>电话/传真:&nbsp;&nbsp;&nbsp;075783785031</dd>
              </dl>
            </b-col>
            <b-col md="6" lg="3" sm="6">
              <dl>
                <dd>联系电话:&nbsp;&nbsp;&nbsp;18680037498</dd>
                <dd>QQ:&nbsp;&nbsp;&nbsp;&nbsp;842823494</dd>
              </dl>
            </b-col>
            <b-col md="12" lg="6" sm="12">
              <dl>
                <dd>
                  联系地址:&nbsp;&nbsp;&nbsp;广东省佛山市南海区桂城街道金科路6号粤港金融科技园1座8层
                </dd>
              </dl>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid="xl">
          <b-row>
            <b-col md="12">
              <div class="zoomlaFoot">
                版权所有@ 2024 广东壹公里数智有限公司 <a href="https://beian.miit.gov.cn" target="_blank" style="color:#8085ad;">粤ICP备19057806号</a>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <!-- <ZoomlaFoot
          :cols="12"
          :md="12"
          class=""
          con="广东壹公里数智有限公司 @ 版权所有"
          :icpOpen="true"
          icp="粤ICP备19057806号"
        /> -->
      </section>
    </footer>
    <Counseling></Counseling>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import Vue from "vue";
import BaiduMap from "vue-baidu-map";
import Counseling from "@/views/Counseling.vue";
import MyRouterLink from "@/components/MyRouterLink.vue"
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "g5x6yVgmvGsvnA3QhAjbyH6BwYl8TupL",
});
export default {
  components: {
    Counseling,
    MyRouterLink,
  },
  data() {
    return {
      bossContact: {
        name: "",
        ename: "",
        telephone: "",
        email: "",
        reply: "",
      },
      logoUrl: require("./assets/images/home_slices/Group 1761.png"),
      // vis: true,
      scrollTop: 0,
      nav: [],
      navlist: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "产品中心",
          path: "/productCenter",
          list: [
            {
              title: "AI数据中台-BossiPaaS",
              path: "/BossiPaasCenter",
              text: "快速实现企业级集成",
            },
            {
              title: "AI报表自动化-BossReport",
              path: "/BossReportCenter",
              text: "提升企业主数据管理水平",
            },
            // {
            //   title: "壹公里企业门户",
            //   path: "/oneCompanyDoor",
            //   text: "一站式协同平台",
            // },
            {
              title: "AI中台-BossAI",
              path: "/BossAI",
              text: "性价比极高的企业AI大模型",
            },
            {
              title: "AI开发平台-BossMa",
              path: "/bossMaCenter",
              text: "低代码开发个性化场景",
            },
            {
              title: "AI数据决策平台-BossBI",
              path: "/BossBICenter",
              text: "海量数据清洗、加工、采集",
            },
          ],
        },
        {
          title: "行业解决方案",
          path: "/solution",
          list: [
            {
              title: "家电行业",
              path: "/householdApplianceSolution",
            },
            {
              title: "电子行业",
              path: "/electronSolution",
            },
            {
              title: "机械管理行业",
              path: "/machineryManagementSolution",
            },
            {
              title: "食品行业",
              path: "/foodstuffSolution",
            },
            {
              title: "家居行业",
              path: "/homeSolution",
            },
            {
              title: "金属行业",
              path: "/metalSolution",
            },
            {
              title: "五金行业",
              path: "/hardwareSolution",
            },
            {
              title: "陶瓷行业",
              path: "/ceramicsSolution",
            },
          ],
        },
        {
          title: "成功案例",
          path: "/product",
        },
        // {
        //   title: "成功案例",
        //   path: "/service",
        // },
        {
          title: "支持与服务",
          path: "/serve",
          listTitle: true,
          list: [
            {
              align: "left",
              title: "产品文档",
              path: "http://47.112.216.86:3000/",
              list: [
              {
                title: "BossBI产品使用文档",
                path: "http://47.112.216.86:3000/#/bi/bi",
              },{
                title: "企业门户产品使用文档",
                path: "/**",
              },{
                title: "应用系统集成平台使用文档",
                path: "http://47.112.216.86:3000/#/bossdi/bossdi",
              },{
                title: "BossMa低代码平台使用文档",
                path: "http://47.112.216.86:3000/#/bossma/bossma",
              },{
                title: "IoT智能物联网平台使用文档",
                path: "/*******",
              },]
            },
            {
              align: "right",
              title: "客户服务",
              list: [{
                title: "售前咨询",
                path: "/frontserve",
              },
              {
                title: "售后咨询",
                path: "/afterserve",
              },
              {
                title: "客户成功服务",
                path: "/serve",
              },
              {
                title: "投诉与反馈",
                path: "/complain",
              }]
            }
          ],
        },
        // {
        //   title: "产品支持",
        //   path: "/quarterly",
        // },
        {
          title: "关于壹公里",
          path: "/about",
          list: [
            {
              title: "企业简介",
              path: "/about",
            },
            {
              title: "新闻资讯",
              path: "/news",
            },
            // {
            //   title: "资质荣誉",
            //   path: "/ziji",
            // },
          ],
        },
      ],
      footerNavList: [
        {
          title: "产品中心",
          path: "/productCenter",
          list: [
            {
              title: "AI数据中台-BossiPaaS",
              path: "/BossiPaasCenter",
            },
            {
              title: "AI报表自动化-BossReport",
              path: "/BossReportCenter",
            },
            // {
            //   title: "壹公里企业门户",
            //   path: "/oneCompanyDoor",
            // },
            {
              title: "AI中台-BossAI",
              path: "/BossAI",
            },
            {
              title: "AI开发平台-BossMa",
              path: "/bossMaCenter",
            },
            {
              title: "AI数据决策平台-BossBI",
              path: "/BossBICenter",
            },
          ],
        },
        {
          title: "行业解决方案",
          path: "/solution",
          list: [
            {
              title: "家电行业",
              path: "/householdApplianceSolution",
            },
            {
              title: "电子行业",
              path: "/electronSolution",
            },
            {
              title: "机械管理行业",
              path: "/machineryManagementSolution",
            },
            {
              title: "食品行业",
              path: "/foodstuffSolution",
            },
            {
              title: "家居行业",
              path: "/homeSolution",
            },
            {
              title: "金属行业",
              path: "/metalSolution",
            },
            {
              title: "五金行业",
              path: "/hardwareSolution",
            },
            {
              title: "陶瓷行业",
              path: "/ceramicsSolution",
            },
          ],
        },
        {
          title: "支持与服务",
          path: "/serve",
          list: [
            {
              title: "售前咨询",
              path: "/frontserve",
            },
            {
              title: "售后咨询",
              path: "/afterserve",
            },
            {
              title: "客户成功服务",
              path: "/serve",
            },
            {
              title: "投诉与反馈",
              path: "/complain",
            },
          ],
        },
        {
          title: "关于壹公里",
          path: "/about",
          list: [
            {
              title: "企业简介",
              path: "/about",
            },
            {
              title: "新闻咨询",
              path: "/news",
            },
            // {
            //   title: "资质荣誉",
            //   path: "/ziji",
            // },
          ],
        },
      ],
      footerRouterHideArr: ["apply","BossDoc"],
      routerName: "",
      routerPath: "",
    };
  },
  methods: {
    contactway3() {
      this.$router.push("/apply");
    },
    addContact() {
      if (this.bossContact.name.length < 1) {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (
        this.bossContact.name.length > 20 ||
        this.bossContact.ename.length > 20 ||
        this.bossContact.email.length > 20 ||
        this.bossContact.reply.length > 1000 ||
        this.bossContact.telephone.length > 11
      ) {
        this.$message({
          message: "数据输入有误，注意格式",
          type: "warning",
        });
        return;
      }
      this.$http
        .post("/contact/add", this.bossContact)
        .then((response) => {
          // 处理成功情况
          if (response.data.code == 200) {
            this.$message({
              message: "添加成功，感谢你的信任",
              type: "success",
            });
            this.bossContact = {
              name: "",
              ename: "",
              telephone: "",
              email: "",
              reply: "",
            };
          }
        })
        .catch((error) => {
          // 处理错误情况
          console.log(error);
        })
        .then(function () {
          // 总是会执行
        });
    },
    async mounted() {
      // console.log("软件事业部团队开发-cgc,cdh");
      var wow = new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 100,
        mobile: true,
        live: true,
      });
      wow.init();
      //获取滚动条
      window.addEventListener(
        "scroll",
        (e) => {
          // console.log(e.srcElement.scrollingElement.scrollTop)
          this.scrollTop =
            document.documentElement.scrollTop ||
            window.pageYOffset ||
            document.body.scrollTop;
        },
        true
      );
    },
    navBtnGroupHead(path) {
      if (path) {
        window.open(path);
      }
    }
  },
  watch: {
    scrollTop(v) {
      // if (v < 10) {
      //   this.vis = true;
      // } else {
      //   this.vis = false;
      // }
    },
    $route(v) {
      console.log(v);
      this.routerName = v.name;
      this.routerPath = v.path;
    },
  },
};
</script>
<style lang="scss">
@import "assets/global.scss";


.nav-link,
button {
  outline: none;
}

.two-column {
  column-count: 2;
  column-gap: 20px;
}

.nav-item {
  break-inside: avoid;
}

.contact {
  z-index: 4;
  opacity: 1;
  background: #ffffff;
  background: url(./assets/images/bossafter/1754.png) no-repeat center;
  padding: 70px 0;
  overflow: hidden;
  background-size: cover;

  .content {
    text-align: center;

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 30px;
      font-weight: normal;
      margin-top: 40px;
    }

    button {
      background: rgba(0, 0, 0, 0);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #0139d0;
      font-size: 48px;
      padding: 8px 44px;
      margin-top: 40px;
      color: #0139d0;
      font-weight: 800;
      width: auto;
    }
  }
}

.contact2 {
  background: url(./assets/images/bossafter/Rectangle30.png) no-repeat center;
  padding: 70px 0;
  overflow: hidden;
  background-size: cover;
  color: #e5efff;
  opacity: 1;
  z-index: 4;

  .content {
    text-align: center;

    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 24px;
      font-weight: 100;
      margin-top: 20px;
    }

    label {
      text-align: left;
      display: block;
      font-size: 12px;
      margin-top: 26px;
    }

    input {
      width: 100%;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 8px 16px 0px rgba(44, 81, 154, 0.16);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #ffffff;
    }

    button {
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #00a3ff;
      width: auto;
      padding: 8px 66px;
      margin-top: 26px;
      color: #012296;
      font-weight: 600;
    }
  }
}

.footer {
  background: #000842;
  padding: 70px 0;
  padding-bottom: 30px;
  overflow: hidden;
  color: #e5efff;

  .footer1 {
    cursor: pointer;
    padding: 20px 0;
    border-bottom: 1px solid rgba(90, 96, 111, 0.48);

    dt {
      font-size: 20px;
    }

    dd {
      color: #e5efff;
      font-size: 12px;
      padding: 2px 0;
    }

    .code {
      text-align: right;

      dt {
        font-size: 32px;
      }

      .codeImg {
        padding: 10px;
        background: #192050;
        border-radius: 6px 6px 6px 6px;
        overflow: hidden;
        width: 220px;
        display: inline-block;
        margin-left: 10px;

        img {
          width: 72px;
          height: 72px;
          float: left;
        }

        .text {
          width: calc(100% - 72px);
          text-align: center;
          padding: 16px 15px;
          font-size: 14px;
          display: inline-block;
          float: right;
        }
      }
    }
  }

  .footer2 {
    padding: 20px 0;
    border-bottom: 1px solid rgba(90, 96, 111, 0.48);

    & > * {
      margin-top: 10px;
    }

    dd {
      color: #e5efff;
      font-size: 12px;
      padding: 2px 0;
    }
  }

  .zoomlaFoot {
    height: 32px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #8085ad;
    line-height: 32px;
    padding: 30px 0;
  }
}

.slide-left-enter {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-enter-active {
  transition: all 0.5s ease;
}

.slide-left-leave-to {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.slide-left-leave-active {
  transition: all 0.5s ease;
}

.contace_mapBox {
  height: 100%;
  border: 0;
}

#app {
  font-family: PingFang SC, Microsoft YaHei;
}

.logo {
  display: flex;
  float: right;
  justify-content: flex-start;
  align-items: center;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
}

.inav {
  position: fixed;
  font-family: auto;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 1000;
  transition: 0.5s;
  margin: 0;

  .header-right {
    float: right;
    padding: 0;
    width: 300px;

    * {
      height: 100%;
      line-height: 56px;
      float: left;
      width: 50%;
      text-align: center;
    }

    .useCenter {
      background: #0139d0;
      color: #fff;
      cursor: default;
    }
  }

  li {
    margin: 0 6px;
  }

  &.bg {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 2px 4px 0px rgba(68, 69, 71, 0.24);
    .dropdown-menu-right {
      right: inherit;
    }
    .nav-item {
      .nav-link,
      .new-link {
        color: #000;
        position: relative;
        width: max-content;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          height: 2px;
          background: linear-gradient(90deg, #0139d0 0%, #00a3ff 100%);
          border-radius: 4px;
          transform: translateX(-50%);
          width: 0;
          transition: 0.2s all;
        }
      }
      .new-link::before {
        bottom: -10px;
        left: calc(50% + 5px);
        width: calc(100% + 14px);
      }
      .nav-link:hover {
        font-weight: 800;

        // border-bottom: 2px solid #00a3ff;
        &::before {
          width: calc(100% - 16px);
        }
      }

      // 鼠标导航active
      .router-link-exact-active {
        // color: #ff6533;
        font-weight: 800;

        &::before {
          width: calc(100% - 16px);
        }
      }
    }

    h1 {
      color: #000;
    }
  }

  .show {
    li {
      display: block;
    }

    .nav-link {
      color: #000;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.imgcall {
  position: absolute;
  right: 280px;
  top: 7px;
}
.dropdown-item{
  width: max-content;
  background: #fff;
  &:hover{
    background: #fff;
  }
  &:active {
    background: #fff;
  }
  .el-row{
    max-width: 600px;
    min-width: 400px;
    h4{
      font-size: 16px;
      font-weight: 800;
      padding: 14px 0;
      border-bottom: 1px solid #DCE0E4;
    }
    .nav-link{
      // height: 40px;
      width: 100% !important;
      border-radius: 3px;
      p{
        margin: 0;
        font-weight: 800;
      }
      .title{
        font-size: 14px;
      }
      .text{
        font-size: 12px;
        color: #6D7E8E;
      }
      &:hover {
        background: #E6F0FE;
        .title {
          color: #0139D0;
        }
        &::before {
          width: 0 !important;
        }
      }
      &.router-link-exact-active{
        background: #E6F0FE;
        .title {
          color: #0139D0;
        }
        &::before{
          width: 0 !important;
        }
      } 
    }
  }
}
</style>