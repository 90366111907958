<template>
  <div class="home">
    <div>
      <div class="carousel-container">
        <!-- 这里是内容 -->
        <b-carousel id="carousel-fade" fade indicators>
          <b-carousel-slide v-for="item in banner" :key="item.id" :img-src="item.img">
            <div class="boss-content" style="z-index: 9;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;">
              <div style="position: relative;bottom: 250px;right: 55px;color:#000">
                <div class="boss-content" style="width: 400px;">
                  <h1 style="font-weight: bold;font-size: 50px">{{ item.title }}</h1>
                  <span style="font-weight: bold;font-size: 20px">{{ item.product }}</span>
                  <br>
                  <span style="font-size: 20px">{{ item.message }}</span>
                  <br>
                  <el-button style="margin-top:30px" class="image-button" @click="tryUse()">免费试用</el-button>
                </div>
              </div>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
    <div class="container" style="clear: both;">
      <div class="floating-div">
        <!-- 这里是要悬浮的内容 -->
        <div class="boss-content">
          <b-container fluid="xl">
            <el-row :gutter="24" style="width: 100%;padding: 0; margin: 0;">
              <el-col :span="4" class="image-float-style" style="margin-left: 30px">
                <div style="margin-top: 10px;margin-bottom: 10px">
                  <el-col style="width: 40%;padding: 0; margin: 0; height: 100%">
                    <div class="grid-content bg-purple">
                      <div class="demo-image">
                        <div class="block" v-for="fit in fits" :key="fit">
                          <el-image style="width: 50px; height: 60px" :src="url" :fit="fit">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col style="width: 60%; background: white;padding: 0; margin: 0;">
                    <div class="center-p">
                      <span style="font-size: 20px; color: #2433cb;font-weight: bolder">300+</span>
                      <p style="font-size: 10px">大型企业用户</p>
                    </div>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="4" class="image-float-style">
                <div style="margin-top: 10px;margin-bottom: 10px">
                  <el-col style="width: 40%;background: white;padding: 0; margin: 0; height: 100%">
                    <div class="grid-content bg-purple">
                      <div class="demo-image">
                        <div class="block" v-for="fit in fits" :key="fit">
                          <el-image style="width: 50px; height: 60px" :src="url1" :fit="fit">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col style="width: 60%; background: white;padding: 0; margin: 0;">
                    <span style="font-size: 20px;color: #2433cb;font-weight: bolder">500+</span>
                    <p style="font-size: 10px">项目实施落地</p>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="4" class="image-float-style">
                <div style="margin-top: 10px;margin-bottom: 10px">
                  <el-col style="width: 40%;background: white;padding: 0; margin: 0; height: 100%">
                    <div class="grid-content bg-purple">
                      <div class="demo-image">
                        <div class="block" v-for="fit in fits" :key="fit">
                          <el-image style="width: 50px; height: 60px" :src="url2" :fit="fit">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col style="width: 60%; background: white;padding: 0; margin: 0;">
                    <span style="font-size: 20px; color: #2433cb;font-weight: bolder">200+</span>
                    <p style="font-size: 10px">API集成</p>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="4" class="image-float-style">
                <div style="margin-top: 10px;margin-bottom: 10px">
                  <el-col style="width: 40%;background: white;padding: 0; margin: 0; height: 100%">
                    <div class="grid-content bg-purple">
                      <div class="demo-image">
                        <div class="block" v-for="fit in fits" :key="fit">
                          <el-image style="width: 50px; height: 60px" :src="url3" :fit="fit">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col style="width: 60%;padding: 0; margin: 0;">
                    <span style="font-size: 20px; color: #2433cb;font-weight: bolder">80%+</span>
                    <p style="font-size: 10px">企业IT效率提升</p>
                  </el-col>
                </div>
              </el-col>
              <el-col :span="4" class="image-float-style">
                <div style="margin-top: 10px;margin-bottom: 10px">
                  <el-col style="width: 40%;background: white;padding: 0; margin: 0; height: 100%">
                    <div class="grid-content bg-purple">
                      <div class="demo-image">
                        <div class="block" v-for="fit in fits" :key="fit">
                          <el-image style="width: 50px; height: 60px" :src="url4" :fit="fit">
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col style="width: 60%; background: white;padding: 0; margin: 0;">
                    <span style="font-size: 20px; color: #2433cb;font-weight: bolder">90%</span>
                    <p style="font-size: 10px">客户持续率</p>
                  </el-col>
                </div>
              </el-col>
            </el-row>
          </b-container>
        </div>
      </div>
    </div>
    <section class="partner">
      <div class="boss-content">
      </div>
      <div class="boss-content" style="margin-top: 80px">
        <div style="text-align: center">
          <div class="container">
            <h1 style="font-weight: bold;font-size: 40px;">
              <span style="position: relative;">
                <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                壹公里产品矩阵
              </span>
            </h1>
          </div>
        </div>
        <el-row :gutter="40">
          <el-col :md="7" class="product-matrix-style">
            <div class="grid-content bg-purple">
              <div style="margin-top: 40px"></div>
              <h2 class="font-h2-one" style="margin-bottom: 2.2rem;">AI数据中台-<span style="color: #153869;">BossiPaaS</span>
              </h2>
              <h2 class="font-h2-two">快速实现企业级集成</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl4" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                是一个集中管理和整合企业数据资源的平台；通过数据技术，对海量数据进行采集、计算、存储、加工及AI大模型与数据技术的深度融合实时化的数据服务。提供API接入、API管理、API市场等诸多功能的一站式企业应用集成平台。为企业提供全域集成手段，支撑客户自主方便的处理应用(A)、企业(B)、云(C)、设备(D)的集成对接，构建核心集成能力。
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">统一门户</el-tag>
                <el-tag class="tag">一站式应用集成</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">平台快速管理</el-tag>
                <el-tag class="tag">多平台权限管理</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px;border: none"></el-tag>
              </el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
            <h1></h1>
          </el-col>
          <el-col :md="7" class="product-matrix-style">
            <div class="grid-content bg-purple">
              <div style="margin-top: 40px"></div>
              <h2 class="font-h2-one" style="margin-bottom: 2.2rem;"><span style="color: #153869;">AI中台</span>BossAI
              </h2>
              <h2 class="font-h2-two">性价比极高的企业AI大模型</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl1" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                可能是本地部署价格最低的AI，包括数字人、大模型，以企业知识库、数字员工，让企业现有系统最低成本接入AI。
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">AI对话数字人</el-tag>
                <el-tag class="tag">数字展厅交互</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">数字员工</el-tag>
                <el-tag class="tag">数字营销</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px;">AI大模型调度</el-tag>
              </el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
            <h1></h1>
          </el-col>
          <el-col :md="7" class="product-matrix-style">
            <div class="grid-content bg-purple">
              <div style="margin-top: 40px"></div>
              <h2 class="font-h2-one" style="margin-bottom: 2.2rem;">AI报表自动化-<span style="color: #153869;">BossReport</span></h2>
              <h2 class="font-h2-two">数据效率神器，随时随地看数据！</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl5" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                一款企业级多用户协同AI报表平台自动抓取多系统（ERP\CRM\PLM等）数据为企业管理人员实时提供可追溯的数据企业数据收集、统计工作量降低90%每年为企业节省数千、数万小时的纸质、手工作业！
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">报表自动化</el-tag>
                <el-tag class="tag">类EXCEL操作</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">数据自动更新</el-tag>
                <el-tag class="tag">报表权限管理</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">免费行业模板</el-tag>
              </el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
            <h1></h1>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :md="7" class="product-matrix-style">
            <div style="margin-top: 40px"></div>
            <div class="grid-content bg-purple">
              <h2 class="font-h2-one">AI数据决策平台-<span style="color: #153869;">BossBI</span></h2>
              <h2 class="font-h2-two">海量数据清洗、加工、采集</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl2" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                首个混合云数智化决策平台（BI），是企业数字决策的基本工具，帮助企业管理透明化，实时化，能将主流ERP的标准数据、近百种通用报表，十分钟快速部署、上线使用。
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">全局数据监控</el-tag>
                <el-tag class="tag">决策模型算法支持</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">全业务流经营管理</el-tag>
                <el-tag class="tag">指挥控制中心</el-tag>
              </el-row>
              <el-row>
              </el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
            <h1></h1>
          </el-col>
          <!-- <el-col :md="7" class="product-matrix-style">
            <div style="margin-top: 40px"></div>
            <div class="grid-content bg-purple">
              <h2 class="font-h2-one">数据中台-<span style="color: #153869;">BossOne</span></h2>
              <h2 class="font-h2-two">快速实现企业级集成</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl1" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                一个集中管理和整合企业数据资源的平台；通过数据技术，对海量数据进行采集、计算、存储、加工及AI+DB (人工智能+数据库)大模型与数据技术深度融合的实时化数据服务。
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">数据实时可视化</el-tag>
                <el-tag class="tag">全价值链构建</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">AI人工智能+数据库</el-tag>
                <el-tag class="tag">数据统一标准</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag">服务门户总聚焦</el-tag>
              </el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
            <h1></h1>
          </el-col> -->
          <el-col :md="7" class="product-matrix-style">
            <div style="margin-top: 40px"></div>
            <div class="grid-content bg-purple">
              <h2 class="font-h2-one">AI开发平台-<span style="color: #153869;">BossMa</span>
              </h2>
              <h2 class="font-h2-two">低代码开发个性化场景</h2>
              <div class="demo-image">
                <div class="block" v-for="fit in fits" :key="fit">
                  <el-image style="width: 100px; height: 100px" :src="productUrl3" :fit="fit"></el-image>
                </div>
              </div>
            </div>
            <h2 class="newHome-one-product-font">产品介绍</h2>
            <div class="newHome-one-product-font-div-p">
              <p>
                无需编码（0代码）或通过少量代码就可以快速生成应用程序的开发平台。也是一款图形化、拖拉拽方式快速实现企业数字化转型中的创新应用、支持用少量代码扩展实现个性化需求的数字技术工具平台。
              </p>
              <br>
            </div>
            <div style="margin-top: 60px">
              <h2 style="font-size: 20px;font-weight: bolder">典型功能</h2>
            </div>
            <p></p>
            <div style="height: 150px">
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">拖拉拽快速搭建</el-tag>
                <el-tag class="tag">丰富控件拓展</el-tag>
              </el-row>
              <el-row>
                <el-tag class="tag" style="margin-right: 10px">个性化定制</el-tag>
                <el-tag class="tag">可视化布局</el-tag>
              </el-row>
              <el-row></el-row>
            </div>
            <br>
            <div style="margin-bottom: 40px">
              <el-button class="image-button-min" @click="getProductIfo()">了解更多</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="partner" style="margin-top:60px">
      <div class="container">
        <div>
          <div style="position: relative;">
            <b-row class="mb-3 tab xs-0 no-gutters">
              <b-col md="4" class="tablist">
                <div>
                  <img :src="companyInfo[companyType].background" style="width: 100% ;height: 450px;z-index: 1" />
                </div>
              </b-col>
              <b-col md="8">
                <div>
                  <div class="partner-right">
                  </div>
                  <div class="float-div">
                    <!-- <div class="container">
                    <img :src="successUrl3"/>
                  </div> -->
                    <div class="float-success">
                      <h2 style="font-size: 45px; color: white ">
                        <span style="position: relative;">
                          <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                          行业标杆案例
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="float-div-WABON">
            <div class="container">
              <div class="boss-content">
                <b-container fluid="xl" style="background: white;padding: 0px 0px">
                  <b-row class="mb-3 tab xs-0 no-gutters w-100"
                    style="box-shadow: 3px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);">
                    <b-col md="3" class="tablist" style="text-align: center;background: white">
                      <div style="width: 100% ;height: 330px;">
                        <div style="padding-top: 150px;padding-bottom:120px;padding-left: 30px;padding-right: 30px">
                          <div style="width: 200px ; height: 25px;background: #cbe1fc" />
                        </div>
                      </div>
                      <div class="float-LONDOD">
                        <h3>{{ companyInfo[companyType].name }}</h3>
                      </div>
                    </b-col>
                    <b-col md="9">
                      <div style="width: 100% ;height: 300px;">
                        <div style="display:flex;justify-content: space-around;line-height: 100px;">
                          <div style="" @mouseenter="handleHover(0)">
                            <img :src="companyType == 0 ? companyInfo[0].icon : companyInfo[0].icon" :style="{'opacity':companyType == 0 ?'1':'0.2'}" style="z-index:2;" />
                          </div>
                          <div style="" @mouseenter="handleHover(1)">
                            <img :src="companyType == 1 ? companyInfo[1].icon : companyInfo[1].icon" :style="{'opacity':companyType == 1 ?'1':'0.2'}"
                              style="z-index:2;width: 100px;" />
                          </div>
                        </div>
                        <el-row>
                          <div style=" height: 200px; margin-right: 20px">
                            <p
                              style="margin-top: 0px;font-size: 13px;padding-right: 50px;margin-left: 20px;line-height: 26px;">
                              &nbsp;&nbsp;&nbsp;&nbsp;{{ companyInfo[companyType].introduction }}
                            </p>
                            <div style="margin-bottom: 20px">
                              <div style="float: left;margin-left: 20px">
                                <el-button class="image-button-min" @click="docs(companyInfo[companyType].id)">了解详情</el-button>
                              </div>
                              <div style="float: right;margin-right: 50px">
                                <el-link type="primary"
                                  style="font-size: 15px;font-weight: bold;color: #7879af;margin-top: 5px" @click="goMoreExample()">更多案例>
                                </el-link>
                              </div>
                            </div>
                          </div>
                        </el-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="partner" style="margin-top:60px">
      <div style="padding-top: 50px;background: white">
        <div style="position: relative;">
          <div class="cover-ALL-industry">
            <div class="float-image-title">
              <div class="container" style="margin-top: 50px">
                <h1 class="title-logo">
                  <span style="position: relative;">
                    <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                    应用场景展示
                  </span>
                </h1>
              </div>
              <!-- <div class="float-title">
              <img :src="successUrl3"/>
            </div> -->
            </div>
          </div>
          <div class="float-div-inner">
            <div class="boss-content" style="margin-top: 50px">
              <div class="container">
                <b-container fluid="xl" style="background: white;padding: 0px 0px">
                  <div style="height: 450px;box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);" class="custom">
                    <el-col :md="12" style="width: 100%">
                      <div class="tabHome-header">
                        <div class="tabHome" :class="{ active: activeTab === 'first' }" @click="changeTab('first')">统一门户
                        </div>
                        <div class="tabHome" :class="{ active: activeTab === 'second' }" @click="changeTab('second')">
                          全价值链</div>
                        <div class="tabHome" :class="{ active: activeTab === 'third' }" @click="changeTab('third')">
                          可视化经营</div>
                        <div class="tabHome" :class="{ active: activeTab === 'fourth' }" @click="changeTab('fourth')">
                          数字人展厅</div>
                        <!-- <div class="tabHome" :class="{ active: activeTab === 'five' }" @click="changeTab('five')">数据资产</div> -->
                      </div>
                    </el-col>
                    <br>
                    <div style="margin-top: 100px;height: 300px">
                      <div v-for="(item, index) in scenariosImg" :key="index" v-show="activeTab === item.index"
                        style="height: 100%;width: 100%;padding-left: 20px;margin-top: 20px;">
                        <b-container fluid="xl" style="background: white;padding: 0px 0px">
                          <el-row :gutter="40">
                            <el-col :md="12">
                              <div>
                                <img :src="scenariosImg[index].img" style="height: 300px;width: 100%">
                              </div>
                            </el-col>
                            <el-col :md="12">
                              <div style="height: 100%;width: 100%">
                                <div style="height: 40px;">
                                  <div style="text-align: center">
                                    <div
                                      style="height: 25px;width: 150px;background: #b1cdfa;margin-top: 12px;float: left">
                                    </div>
                                  </div>
                                  <div style="position: relative;top: 40px;left: -150px">
                                    <h1 style="font-size: 30px;top: -45px;position: relative;letter-spacing:2px">
                                      {{ scenariosImg[index].title }}</h1>
                                  </div>
                                </div>
                                <div style="height: 130px;;margin-top: 40px">
                                  <p style="font-size: 13px;line-height: 26px;">
                                    {{ scenariosImg[index].description }}
                                    <!-- 壹公里焦与当下家电企数字化转型需求，蓄势革新，以的BossMa低代码开发平台和企业集成平台两大核心产品，提供从需求、开发、测试、部署到运维的全生命周期建设能力，
                                  搭建企业智能化管理平台。帮助企业建立IT架构规划，形成由上到下的解决方案，搭建企业IT与业务、业务与业务之间敏捷连接。 -->
                                  </p>
                                  <!-- <div  style="height: 60px;width: 100%;margin-right: 10px;margin-left: 5px">
                                  <img :src="allTypeLogoImg1" style="height: 60px;width: 120px;margin-right: 10px;margin-left: 5px">
                                  <img :src="allTypeLogoImg2" style="height: 60px;width: 120px;margin-right: 10px;margin-left: 5px">
                                  <img :src="allTypeLogoImg2" style="height: 60px;width: 120px;margin-right: 10px;margin-left: 5px">
                                </div> -->
                                  <div style="margin-top: 28px">
                                    <el-button class="image-button-min" @click="tryUse()">了解详情</el-button>
                                  </div>
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </b-container>
                      </div>
                      <!-- <div v-show="activeTab === 'second'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                      <div style="margin-top: 30px;margin-left: 100px"></div>
                      <div class="float-font-shallow" >
                        <h1 style="font-size: 22px;font-weight: bolder">数据质量</h1>
                      </div>
                    </div>
                    <div v-show="activeTab === 'third'"   style="height: 100%;padding-left: 20px;margin-top: 20px">
                      <div style="margin-top: 30px;margin-left: 10px"></div>
                      <div class="float-font-shallow" >
                        <h1 style="font-size: 22px;font-weight: bolder">数据质量</h1>
                      </div>
                    </div>
                    <div v-show="activeTab === 'fourth'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                      <div style="margin-top: 30px;margin-left: 10px"></div>
                      <div class="float-font-shallow" >
                        <h1 style="font-size: 22px;font-weight: bolder">数据服务</h1>
                      </div>
                    </div>
                    <div v-show="activeTab === 'five'"  style="height: 100%;padding-left: 20px;margin-top: 20px">
                      <div style="margin-top: 30px;margin-left: 10px"></div>
                      <div class="float-font-shallow" >
                        <h1 style="font-size: 22px;font-weight: bolder">数据资产</h1>
                      </div>
                    </div> -->
                    </div>
                  </div>
                </b-container>
              </div>
            </div>
          </div>
          <div style="height: 300px;background: white">
          </div>
        </div>
      </div>
    </section>
    <!-- 三百家企业的共同选择 -->
    <section class="partner">
      <div class="img-up-one">
        <div class="" style="width: 1200px;margin: 0 auto;">
          <div style="text-align: center;" class="float-low">
            <div class="container">
              <h1 class="title-logo">
                <span style="position: relative;">
                  <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                  壹公里，专注制造业的数据中台服务商
                </span>
              </h1>
            </div>
            <!-- <div class="float-customer1">
              <img :src="successUrl3"/>
            </div> -->
          </div>
          <b-container fluid="xl" style="margin-bottom: 0">
            <el-row :gutter="40">
              <el-col :md="12">
                <div class="grid-content bg-purple-light">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="4" :offset="1" class="mycol">
                      <img :src="upOneUrl1">
                    </el-col>
                    <el-col :md="16" :offset="3" class="mycol">
                      <br>
                      <h1 class="up-one-title-font">
                        产品丨满足企业快速发展
                      </h1>
                      <p class="up-one-font">
                        技术平台优势，数据中台满足企业个性化配置
                        <br>互联网化设计，操作简单、易用
                        <br>产品功能全面、灵活、实用、安全
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="12">
                <div class="grid-content bg-purple">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="4" :offset="1" class="mycol">
                      <img :src="upOneUrl2">
                    </el-col>
                    <el-col :md="16" :offset="3" class="mycol">
                      <br>
                      <h1 class="up-one-title-font">
                        服务丨原厂+7×24小时响应
                      </h1>
                      <p class="up-one-font">
                        本地化服务保障，研发为客户提供绿色支持通道
                        <br>丰富的应用案例和行业解决方案
                        <br>引入管理咨询顾问参与项目，共同解决项目中遇到的挑战
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="40">
              <el-col :md="12">
                <div class="grid-content bg-purple-light">
                  <el-row class="myrow">
                    <br>
                    <el-col :md="4" :offset="1" class="mycol">
                      <img :src="upOneUrl3">
                    </el-col>
                    <el-col :md="16" :offset="3" class="mycol">
                      <br>
                      <h1 class="up-one-title-font">
                        资源丨强大顾问团队支撑
                      </h1>
                      <p class="up-one-font">
                        咨询顾问、业务蓝图顾问、项目实施顾问、开发工程师、售后服务等组织人才保障原厂产品研发、交付团队支持
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :md="12">
                <div class="grid-content bg-purple">
                  <br>
                  <el-row class="myrow">
                    <el-col :md="4" :offset="1" class="mycol">
                      <img :src="upOneUrl4">
                    </el-col>
                    <el-col :md="16" :offset="3" class="mycol">
                      <br>
                      <h1 class="up-one-title-font">
                        标杆丨打造成行业标杆
                      </h1>
                      <p class="up-one-font">
                        众多头部制造企业案例
                        <br>做一个成一个，把每个企业当VIP标杆建设
                      </p>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </b-container>
        </div>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0px">
      <div>
        <div class="boss-content">
          <b-container fluid="xl" style="margin-bottom: 0px">
            <br>
            <br>
            <br>
            <div style="margin: 0 auto;text-align: center;" class="float-low">
              <div class="container">
                <h1 class="title-logo">
                  <span style="position: relative;">
                    <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                    企业动态
                  </span>
                </h1>
              </div>
            </div>
            <br>
            <br>
            <br>
            <div>
              <el-row :gutter="40">
                <el-col :md="12">
                  <div class="grid-content bg-purple-light">
                    <br>
                    <el-row class="myrow">
                      <div style="height: 250px;">
                        <img :src="news[0] && news[0].imagePath ? $imgUrl + news[0].imagePath : ''"
                          style="height: 230px;width: 100%">
                      </div>
                      <div style="height: 250px;">
                        <div style="padding: 30px;height: 160px">
                          <h1 class="one-dynamic-h1">{{ news[0] && news[0].title }}</h1>
                          <p style="margin-top: 13px">{{ news[0] && news[0].description }}</p>
                        </div>
                        <div style="padding: 30px">
                          <span>{{ TimestampToDate(news[0] && news[0].time) }}</span>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-col>
                <el-col :md="12">
                  <div class="grid-content bg-purple">
                    <br>
                    <el-row class="myrow">
                      <div style="height: 500px;">

                        <el-row style="height: 420px">
                          <br>
                          <br>
                          <div style="margin: 0 0 30px 40px;width: 100%;padding: 0;">
                            <div v-for="item in news" :key="item.titl">
                              <label @click="getNewsInfo(item.url)" style="cursor: pointer;">
                                <h1 class="one-dynamic-h1">{{ item.title }}</h1>
                                <span>{{ TimestampToDate(item.time) }}</span>
                                <br>
                                <img :src="lineUrl" style="width: 80%;">
                              </label>
                            </div>
                          </div>
                        </el-row>
                        <div style="float: right;padding-right: 80px;">
                          <span> <el-link type="primary" style="font-weight: bold;color: #5761b3;font-size: 15px"
                              @click="moreActive()">更多动态></el-link></span>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <br>
            <br>
            <br>
          </b-container>
        </div>
      </div>
    </section>
    <section class="partner" style="padding-bottom: 0px;margin-top: 0">
      <div style="background: whitesmoke;height: 100%;padding: 0;margin-bottom: -25px;position: relative;">
        <div class="boss-content" style="padding-bottom: 120px">
          <b-container fluid="xl" style="margin-bottom: 0px">
            <div style="margin: 0 auto;text-align: center;" class="float-low">
              <br>
              <br>
              <br>
              <br>
              <div class="container">
                <h1 style="font-size: 40px;font-weight: bold">
                  <span style="position: relative;">
                    <img style="position: absolute;top: -8px;left: -30px;" :src="successUrl3">
                    众多企业的信赖之选
                  </span>
                </h1>
                <img :src="subTitleImg" style="width: 100%;height: 100%;padding: 15px;" />
              </div>
              <!-- <div class="float-customer">
              <img :src="successUrl3"/>
            </div> -->
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <div style="width: 100%" class="container">
              <img :src="companyUrl" style="width: 100%;">
            </div>
            <div style="text-align: right;padding: 15px;font-size: 12px;color: #6D7E8E;">*仅显示部分客户，排名不分先后</div>
          </b-container>
        </div>
      </div>
      <div
        style="width: 100%;background-image: url('/src/assets/images/home_slices/Group 1754.png');padding-bottom: 0px">
      </div>
    </section>
    <Contact />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import { WOW } from "wowjs";
import images from "swiper/src/components/core/images";

export default {
  name: "Home",
  components: {
    Contact,
  },
  metaInfo: {
    title: "广东壹公里数智科技有限公司",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        vmid: "description",
        name: "description",
        content: "广东壹公里数智科技有限公司官方网站",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "物联网应用、智能建筑",
      },
    ],
  },
  data() {
    return {
      activeTab: 'first',
      company: {
        name: '',
        companyName: '',
        phone: '',
        address: '',
        need: '',
      },
      activeName: 'second',
      backgroundImageUrl: '../assets/images/home_slices/Group 1754@2x.png',
      fits: ['contain'],
      url: require("../assets/images/home_slices/point_01.png"),
      url1: require("../assets/images/home_slices/point_02.png"),
      url2: require("../assets/images/home_slices/point_03.png"),
      url3: require("../assets/images/home_slices/point_04.png"),
      url4: require("../assets/images/home_slices/point_05.png"),
      productUrl: require("../assets/images/home_slices/PD_icon_BossDI 1.png"),
      productUrl1: require("../assets/images/home_slices/PD_icon_Jicheng.png"),
      productUrl2: require("../assets/images/home_slices/PD_icon_BossBI 1.png"),
      productUrl3: require("../assets/images/home_slices/PD_icon_BossMa2 1.png"),
      productUrl4: require("../assets/images/home_slices/PD_icon_Menhu 1.png"),
      productUrl5: require("../assets/images/home_slices/PD_icon_IoT 1.png"),
      successUrl1: require("../assets/images/home_slices/lianbangbg.png"),
      successUrl2: require("../assets/images/home_slices/Group 1781.png"),
      successUrl3: require("../assets/images/home_slices/Subtract(1).png"),
      successUrl4: require("../assets/images/home_slices/Rectangle 30@2x.png"),
      successUrl5: require("../assets/images/home_slices/LANDBOND_Logo 1.png"),
      successUrl6: require("../assets/images/home_slices/万和新logo组合（让家更温暖） 1.png"),
      companyUrl: require("../assets/images/home_slices/Group 1806.png"),
      companyContactUrl: require("../assets/images/home_slices/Group 1781(1).png"),
      upOneUrl1: require("../assets/images/home_slices/RE_icon_Location.png"),
      upOneUrl2: require("../assets/images/home_slices/RE_icon_Cover.png"),
      upOneUrl3: require("../assets/images/home_slices/RE_icon_Customers.png"),
      upOneUrl4: require("../assets/images/home_slices/RE_icon_Product.png"),
      lineUrl: require("../assets/images/home_slices/Rectangle 4515.png"),
      allTypeImg: require("../assets/images/home_slices/Group 1785.png"),
      subTitleImg: require("../assets/images/home_slices/subhead.svg"),
      scenariosImg: [{
        index: 'first',
        img: require("../assets/images/home_slices/tongyi.jpg"),
        title: '统一门户',
        description: '跨平台无缝连接，统一管理，统一调度。用户可轻松在一个平台上获取各系统所需信息，无需频繁切换登录，极大提升工作效率。有效整合企业内外资源，避免信息分散和重叠，减少数据错漏，确保管理的规范性和准确性。高效协同，保障团队协作顺畅。'
      }, {
        index: 'second',
        img: require("../assets/images/home_slices/quanjia.png"),
        title: '全价值链',
        description: '销售、设计、研发、仓储、生产、装配、售后等全业务流程高效串联，确保各个环节紧密相连。通过数据的深度下钻分析，企业能够精准把握各环节的运营状况，确保流程的可控性和透明度。精准的数据分析为企业提供了强大的运营支持和决策依据。'
      }, {
        index: 'third',
        img: require("../assets/images/home_slices/keshi.jpg"),
        title: '可视化经营',
        description: '上百个精心设计的可视化图表，拖拉拽实现自定义数据化大屏，精准展示业务数据全貌。全维度帮助管理者迅速洞察市场趋势，灵活调整资源配置，从而有效降低运营成本，提升整体运营效率。'
      }, {
        index: 'fourth',
        img: require("../assets/images/home_slices/shuziren.jpg"),
        title: '数字人展厅',
        description: '24小时不间断智能客服体验，1:1实现真人形象，结合企业知识库训练而成，为用户提供实时互动，高效个性化服务，极大提升用户参观体验。无论是解答疑问、提供信息还是产品推荐，数字人都能迅速响应，满足用户的需求，成为企业展示实力、提升用户满意度的有力工具。'
      }],
      allTypeLogoImg1: require("../assets/images/productCenter_slices/Group 1896.png"),
      allTypeLogoImg2: require("../assets/images/productCenter_slices/Group 1898.png"),
      allTypeLogoImg3: require("../assets/images/home_slices/Group 1785.png"),
      allBackgroundUrl: require("../assets/images/home_slices/Rectangle 30@2x.png"),
      banner: [
        {
          img: require("../assets/images/home_slices/Carousel_01@2x.png"),
          title: "上中台选壹公里",
          product: "",
          message: "0门槛上手，企业一站式数据分析平台世界500强企业指定数据中台研发合作伙伴"
        },
        {
          img: require("../assets/images/productCenter_slices/BossDI.png"),
          title: "AI报表自动化",
          product: "",
          message: "数据效率神器，随时随地看数据！一款企业级多用户协同AI报表平台自动抓取多系统（ERP\\CRM\\PLM等）数据"
        }
      ],
      news: [],
      aboutUs: [
        {
          img: require("../assets/images/download.jpg"),
          path: "/about",
          title: "公司介绍",
          p: `广东壹公里数智科技有限公司,公司成立于1994年，至今已26年。是佛山地区较早专业从事弱电系统集成工程和软件开发服务的高新科技企业之一。自成立以来，公司以"诚信赢得客户、质量。取信客户、服务巩固客户”为宗旨。`,
        },
        {
          img: require("../assets/images/ziji.png"),
          path: "/ziji",
          title: "资质荣誉",
          p: `公司必须清醒地看到前进中的困难与挑战，正视自身存在的差距与不足，以更加坚定的信念、更加饱满的热情、更加务实的作风、
                    更加强大的合，共同谱写公司发展的新篇章，为公司提供强有力的售后服务与支持。`,
        },
      ],
      tabIndex: 0,
      tab: [
        {
          title: "运营服务",
          content: "我们提供全方位的数字化运营服务。",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          title: "咨询服务",
          content: "我们提供全面的业务咨询服务。",
          img: require("../assets/images/anli/zx1.jpg"),
        },
        {
          title: "集成服务",
          content: "我们提供端到端的系统集成服务。",
          img: require("../assets/images/anli/jc2.jpg"),
        },
      ],
      partner: [],
      anli: [
        {
          name: "智慧运维系统",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          name: "建筑可视化管理系统",
          img: require("../assets/images/anli/建筑可视化管理系统.jpg"),
        },
        {
          name: "终端安全管理系统",
          img: require("../assets/images/anli/终端安全管理系统.jpg"),
        },
        {
          name: "工业互联网标识数据服务系统",
          img: require("../assets/images/anli/工业互联网标识数据服务系统.png"),
        },
        {
          name: "智慧城市建设",
          img: require("../assets/images/anli/智慧城市建设.png"),
        },
        {
          name: "智能感知安防小区",
          img: require("../assets/images/anli/智能感知安防小区.jpg"),
        },
      ],
      caseList: [
        // {
        //   id: 1,
        //   title: "智慧政务",
        //   description:
        //     "“监管+执法”系列信息系统主要是面向市场监督管理局、城市管理和综合执法局、环保局等监管执法部门，构建移动巡查、移动执法、领导督办等业务应用模块，实现事件上报、在线监测、实时监管、预防犯错、执法有据，为监管执法队伍提供强大便利和有力保障。",
        // },
      ],
      bannerH: 0,
      companyType: 0,
      companyInfo: [
        // {
        //   name: "联邦家私",
        //   icon: require('../assets/images/home_slices/LANDBOND_Logo 1.png'),
        //   introduction: "联邦家私作为中国家居民族品牌的扛旗者，联邦家私发展历史悠久。在数字化迅速发展的时代，联邦家私也一直引领着民族家具品牌寻出路、揭新篇，为进一步支撑各项业务需求，自2016年起便逐渐引入ERP、MES、易订货、B2B&B2C、钉钉、3D云设计、TopSolid、Solidworks等软件系统与平台。随着企业规模扩大，管理系统、销售渠道数量不断增加，数据体量愈发庞大，数据分析需求急剧加增。数据本应是企业精准决策的强有力支撑，然而联邦家私的数据来源繁杂、形式多样，让数据管理变得无从下手。在数字化转型的道路上，联邦家私急需一个全面的数据治理解决方案突破瓶颈，保障“一站到家的生活美学全案交付服务”，让数据重新赋能企业发展。",
        //   background: require('../assets/images/home_slices/lianbangbg.png'),
        //   bg_grey: require('../assets/images/home_slices/LANDBOND_Logo 1grey.png'),
        // },
        // {
        //   name: "登奇机电",
        //   icon: require('../assets/images/home_slices/dengqi.png'),
        //   introduction: "登奇机电随着工业4.0时代的到来，伺服电机应用领域也迅速拓宽。作为国产电机行业的领军企业，登奇机电始终站在行业前沿，为高档数控机床、机器人、锂电、新能源、军工装备等众多高端应用领域提供着丰富多样的配套电机，规格数不胜数。对于未数字转型前的登奇机电而言，市场需求的拓张 是机遇也是挑战。传统的生产模式让登奇机电在前进的道路上步履维艰，因为以销定产、非标定制化、大批量柔性生产的过程中，人为不确定因素过多，严重影响了排产计划的顺利进行。与众多制造业企业一样，登奇机电在转型过程中也逐步引入了PLM、ERP、MES、SCADA等管理系统，但数据信息的处理仍过度依靠人工，这在很大程度上限制了企业的生产效率和响应速度。",
        //   background: require('../assets/images/home_slices/dengqibg.png'),
        //   bg_grey: require('../assets/images/home_slices/dengqigrey.png'),
        // },
        {
          name: "博领家居",
          icon: require('../assets/images/home_slices/boling.jpg'),
          introduction: "创立于2006年的博领家居，是一家集产品研发、生产与销售为一体的家居企业。经过十数的发展，博领以富有艺术延展性的产品设计风格而成为行业中的翘楚。作为行业标杆的家具企业，博领坐拥现代化规模工厂及智能仓库、数名国际大牌设计师倾情加盟、拥有覆盖全国主流城市及东南亚等多国的营销网络。致力于为万千消费者打造高品质生活，诠释生活的艺术。",
          background: require('../assets/images/home_slices/bolingbg.jpg'),
          bg_grey: require('../assets/images/home_slices/dengqigrey.png'),
          id:'357fffb9139d45d5a77293044817ed05'
        },
        {
          name: "富信科技",
          icon: require('../assets/images/home_slices/fuxing.jpg'),
          introduction: "广东富信科技股份有限公司，2003年06月06日成立，经营范围包括研发、生产经营半导体热电材料、热电组件、热电系统、半导体热电技术应用产品（半导体制冷/制热产品），热电转换能源类产品，半导体热电技术服务及输出等。",
          background: require('../assets/images/home_slices/bolingbg.jpg'),
          bg_grey: require('../assets/images/home_slices/dengqigrey.png'),
          id:'bc15a67d456f4b3aa7349a5edb671423'
        }
      ]
    };
  },
  created() {
  },
  computed: {
    images() {
      return images
    },
    backgroundStyle() {
      return {
        'background-image': `require(${this.backgroundImageUrl})`,
        // 其他的背景样式属性也可以在这里添加
      };
    }
  },
  async mounted() {
    await this.getNews();
    //案例获取
    let getArchives = await this.$http.get("/solution");
    if (getArchives.status == 200) {
      let list = getArchives.data;
      if (list.length > 5) {
        list.length = 5;
      }
      this.caseList = list;
    }

    //合作伙伴获取
    let cooperation = await this.$http.get("/cooperation");
    console.log(cooperation);
    if (cooperation.status == 200) {
      this.partner = cooperation.data;
    }
    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    let w = 600 / 1920;
    let dw = document.documentElement.offsetWidth || document.innerHeight;
    this.bannerH = w * dw;
    console.log("高度", w * dw);
  },
  methods: {
    docs(val) {
      this.$router.push("/quarterlyFile/programme/" + val);
    },
    goMoreExample() {
      this.$router.push('/product/');
    },
    handleHover(type) {
      console.log(type)
      this.companyType = type
    },
    getProductIfo() {
      this.$router.push('/productCenter/BossiPaasCenter')
    },
    getNewsInfo(val) {
      window.open(val);
    },
    moreActive() {
      this.$router.push('/about/news')
    },
    knowIndo() {
      this.$router.push('/solution/householdApplianceSolution')
    },
    tryUse() {
      this.$router.push('/apply')
    },
    TimestampToDate(val) {
      // console.log('time',val)
      // let date1 = new Date(val);
      // let newTime=date1.toLocaleDateString() + " " + date1.toTimeString().substr(0, 8);
      // 创建 Date 对象
      const date = new Date(val);

      // 提取年、月、日、时、分
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要加 1，然后补齐两位
      const day = date.getUTCDate().toString().padStart(2, '0'); // 补齐两位
      const hours = date.getUTCHours().toString().padStart(2, '0'); // 补齐两位
      const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // 补齐两位

      // 构造年月日时分格式
      // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      // console.log(`${year}-${month}-${day} ${hours}:${minutes}`);
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    getNews() {
      this.$http.get("/getAllNews", {
        params: {
          "query": '',
          "pagenum": 1,
          "pagesize": 4
        }
      })
        .then((response) => {
          // 处理成功情况
          console.log(response.data.data.product);
          this.news = response.data.data.product

          this.news.time = this.TimestampToDate(this.news[0].time)
        })
        .catch((error) => {
          // 处理错误情况
          console.log(error);
        })
        .then(function () {
          // 总是会执行
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },//家电行业，家居行业等导航栏
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    onSubmit() {
      this.toggleModal();
      alert("我们会第一时间联系你");
      console.log("提交");
    },
    changeTab(tab) {
      this.activeTab = tab;
      console.log(tab)
    },
    getBanners() {
      this.$http.get("/banner/getBanner")
        .then((response) => {
          // 处理成功情况
          console.log(response.data.data);
          this.banner = response.data.data
        })
        .catch((error) => {
          // 处理错误情况
          console.log(error);
        })
        .then(function () {
          // 总是会执行
        });
    },
  },
};
</script>
<style>
.partner-right {
  width: 100%;
  height: 450px;
  z-index: 1;
  background: url(../assets/images/home_slices/Group1781.png);
  background-position: top right;
  background-size: auto 100%;
}

.div-x-col {
  width: 301px;
  height: 677px;
}

.carousel-indicators {
  position: absolute;
  bottom: 10%;
  left: 13.4%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 4px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #58bdf1;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-indicator {
  background-color: rgba(255, 255, 255, 0.5);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
}

.float-header {
  position: relative;
  top: -400px;
  left: 450px;
  z-index: 1;
  float: left;
  width: 100%;
}

.tabHome-header {
  position: absolute;
  width: 100%;

}

.tabHome {
  cursor: pointer;
  padding: 10px;
  margin-top: 30px;
  border: 2px solid transparent;
  width: 24%;
  height: 50px;
  float: left;
  text-align: center;
  font-size: 20px;
}

.tabHome.active {
  border-bottom: 2px solid #409EFF;
}

.tabHome-content-product div {
  display: none;
}

.tabHome-content-product div[style*='display: block'] {
  display: block;
}

.tabHome-content-product {
  position: relative;
  height: 100px;
  width: 100%;
}


.home {
  position: relative;
}

.carousel-container {
  position: relative;
}

.b-carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
}

.image-float-style {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin: 40px 20px 40px 0px;
  background: white;
  height: 80px
}

.product-matrix-style {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
  margin-right: 20px;
  margin-left: 20px;
  padding: 0 0;
  text-align: center;
}

.custom.custom-tabs .el-tabs__header {
  margin-right: 29px;
}

.el-header,
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.image-button-min {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;

}

.image-button-min:focus {
  box-shadow: none;
  outline-color: transparent;
}

.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
}

.image-button:focus {
  box-shadow: none;
  outline-color: transparent;
}

.up-one-title-font {
  font-size: 25px;
  font-weight: bold;
}

.up-one-font {
  font-size: 12px;
  font-weight: bold;
}

.mycol {
  height: 150px;
}

.mycol1 {
  height: 420px;
}

.myrow {
  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
}

.one-dynamic-h1 {
  font-size: 15px;
  font-weight: bold;
  width: 400px;
  padding: 0;

}

.font-h2-one {
  font-size: 22px;
  font-weight: bolder
}

.font-h2-two {
  font-size: 18px;
  font-weight: bolder
}

.newHome-one-product-font {
  font-size: 20px;
  font-weight: bolder;
  margin-top: 20px
}

.newHome-one-product-font-div-p {
  height: 100px;
  margin-top: 10px;
  font-size: 13px;
}

.tag {

  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: #ebebeb;
  width: 120px;
}

.img-up-one {
  height: auto;
  margin-bottom: 0;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url('../assets/images/home_slices/Rectangle 4513.png');
}

.tag-min {
  background: white;
  color: black;
  border-radius: 20px;
  border-width: 1px;
  border-color: #ebebeb;
  width: 70px;
}

.item {
  margin-top: 10px;
  margin-right: 40px;
  border-radius: 10px;
}

.container {
  position: relative;
  /* 确保子元素的绝对定位相对于该元素进行定位 */
}

.floating-div {
  position: relative;
  top: -80px;
  left: 1px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-input-company {
  position: relative;
  top: -500px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-success {
  position: relative;
  top: -40px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-customer {
  position: relative;
  top: -80px;
  left: -230px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-solution-all {
  position: relative;
  top: -130px;
  left: -75px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-customer1 {
  position: relative;
  top: -70px;
  left: -340px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-customer-product-title {
  position: relative;
  top: -130px;
  left: -145px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-customer2 {
  position: relative;
  top: -75px;
  left: -105px;
  z-index: 1;
  float: left;
  width: 100%;
}

.title-logo {
  font-size: 40px;
  font-weight: bold
}

.float-contact {
  position: relative;
  top: -300px;
  left: 0px;
  z-index: 1;
  float: left;
  width: 100%;
}



.float-low {
  position: relative;
  /* //top: -100px; left: 0px; */
  z-index: 1;
  float: left;
  width: 100%;
  /* //background: black; */
}

.float-div {
  position: relative;
  top: -400px;
  left: 45px;
  z-index: 1;
  float: left;
  width: 100%;
}

.float-LONDOD {
  position: relative;
  top: -200px;
  left: -56px;
  z-index: 1;
  float: left;
  width: 100%
}

.float-div-WABON {
  position: relative;

  top: -400px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.cover-ALL-industry {
  width: 100%;
  height: 400px;
  text-align: center;
  background-image: url('../assets/images/home_slices/Rectangle 30@2x.png');
}

.float-title {
  position: relative;
  top: -75px;
  left: -120px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-div-inner {
  height: 1px;
  position: relative;
  top: -300px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.float-image-title {
  position: relative;
  top: -250px;
  left: 0px;
  z-index: 2;
  float: left;
  width: 100%;
}

.quote {
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.el-row {
  margin-bottom: 20px;

  /* &:last-child {
    margin-bottom: 0;
  } */
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: white;
}

.bg-purple {
  background: white;
}

.bg-purple-light {
  background: white;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: white;
}
</style>
<style lang="scss" scoped>
.carousel-item {
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
}

@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}

.textColor {
  position: relative;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-image: linear-gradient(90deg,
        #03a9f4,
        #ffeb3b,
        #f441a5,
        #03a9f4);
    background-clip: text;
    clip-path: ellipse(50px 50px at 0% 50%);
    animation: spotlight 2s infinite;
  }
}

@keyframes spotlight {
  0% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }

  50% {
    clip-path: ellipse(50px 50px at 100% 50%);
  }

  100% {
    clip-path: ellipse(50px 50px at 0% 50%);
  }
}

.h1 {
  text-align: center;
  // color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.flex-centen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mode {
  position: relative;
  overflow: hidden;

  img {
    height: 170px;
    width: 100%;
    display: block;
  }

  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    transition: all 1s;
    color: #fff;
    font-size: 1rem;
  }

  &:hover {
    .description {
      bottom: 0;
    }
  }

  &.last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    border: 1px solid;
    color: #409eff;
  }
}

.flexcenten {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


// .img-fluid{
//   max-width: 100%;
//   height: calc(100% - 1rem);
// }</style>